exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agenda-js": () => import("./../../../src/pages/agenda.js" /* webpackChunkName: "component---src-pages-agenda-js" */),
  "component---src-pages-avis-legal-js": () => import("./../../../src/pages/avis-legal.js" /* webpackChunkName: "component---src-pages-avis-legal-js" */),
  "component---src-pages-comarca-js": () => import("./../../../src/pages/comarca.js" /* webpackChunkName: "component---src-pages-comarca-js" */),
  "component---src-pages-contacte-js": () => import("./../../../src/pages/contacte.js" /* webpackChunkName: "component---src-pages-contacte-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-poblacio-js": () => import("./../../../src/pages/poblacio.js" /* webpackChunkName: "component---src-pages-poblacio-js" */),
  "component---src-pages-sobre-nosaltres-js": () => import("./../../../src/pages/sobre-nosaltres.js" /* webpackChunkName: "component---src-pages-sobre-nosaltres-js" */),
  "component---src-templates-comarques-page-js": () => import("./../../../src/templates/comarques-page.js" /* webpackChunkName: "component---src-templates-comarques-page-js" */),
  "component---src-templates-fires-page-js": () => import("./../../../src/templates/fires-page.js" /* webpackChunkName: "component---src-templates-fires-page-js" */),
  "component---src-templates-poblacio-page-js": () => import("./../../../src/templates/poblacio-page.js" /* webpackChunkName: "component---src-templates-poblacio-page-js" */)
}

